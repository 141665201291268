@import './theme.scss';
@import './global.scss';

* {
  outline: 0;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  box-sizing: border-box;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background: $background;
  color: $primaryTextColor;
  width: 100%;
  height: 100%;
}

body,
#root {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: $background;
  font-family: $PrimaryfontFamily!important;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
}

/* Track */
::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background: #00000011;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 14px;
  background: #88888888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}