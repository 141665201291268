.borrowerLabel {
  .closeBtn {
    transition: 0.2s all ease-in-out;
    opacity: 0;
  }

  &:hover {
    .closeBtn {
      opacity: 0.7;
    }
  }
}
