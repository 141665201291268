@import '../../styles/theme.scss';

.pageContainer {
  font-family: $PrimaryfontFamily  !important;

  .breadcrumbsBox {
    ol {
      li {
        a {
          @extend %breadcrumbsBoxStyle;

          &:hover {
            text-decoration: none;
          }
        }

        p {
          @extend %breadcrumbsBoxStyle;
        }
      }
    }
  }
}

.contentBox {
  margin-top: 28px;

  .contentBoxPanel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: $background;
    box-shadow: 0px 4px 18px rgba(75, 70, 92, 0.1);
    border-radius: 0 0 6px 6px;
    padding: 0px 0px 100px 0px;
    min-height: calc(100vh - 250px);
    // overflow-y: auto;
    margin-top: 28px;

    .contentBoxTitle {
      padding: 40px 26px 20px;
      display: flex;
      justify-content: space-between;
    }
  }
}

button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.successBox {
  background: $successColor  !important;
  color: $background;
  border-radius: 3px;
  border-radius: 3px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 5px;
  gap: 3px;
}

.errorBox {
  background: $errorColor  !important;
  color: $background;
  border-radius: 3px;
  border-radius: 3px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 5px;
  gap: 3px;
}

%breadcrumbsBoxStyle {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}