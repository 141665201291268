.actionBtn {
  gap: 16px;
  display: flex;
  margin-top: 84px;

  button {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    padding: 10px 20px;

    &.saveBtn {
      box-shadow: 0px 2px 4px rgba(165, 163, 174, 0.3);
      border-radius: 6px;
      color: $background;
    }

    &.cancelBtn {
      background: rgba(168, 170, 174, 0.16);
      border-radius: 6px;
      color: $grayBgColor;
      border-color: rgba(168, 170, 174, 0.16);
    }
  }
}

.toggleEllipseColor {
  color: #9A9AAF !important;
}