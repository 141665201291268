@import '../../../styles/theme.scss';

.totalBox {
    font-family: $PrimaryfontFamily;
    font-style: normal;
    border: 1px solid #E8EDF2;
    border-radius: 16px;
    background: $background;
    padding: 16px;
    color: #9A9AAF;

    .titleBox {
        @extend %boxStyles;

        .title {
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
        }
    }

    .stateBox {
        @extend %boxStyles;
        margin-top: 1rem;

        .left {
            @extend %boxStyles;
            gap: 10px;

            .icon {
                background: linear-gradient(72.47deg, #26ABE2 22.16%, #3DBBEE 76.47%);
                border-radius: 8px;
                width: 43px;
                height: 32px;
                padding: 4px 7px;
            }

            .text {
                font-family: $secondaryFontFamily;
                font-weight: 700;
                font-size: 28px;
                line-height: 16px;
                letter-spacing: -0.009em;
                color: #07070C;
            }
        }

        .right {
            @extend %boxStyles;
            gap: 4px;

            .icon {
                padding: 0px 8px;
                width: 36px;
                height: 25px;
            }

            .text {
                font-family: $secondaryFontFamily;
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                display: flex;
                align-items: flex-end;
                letter-spacing: -0.009em;
            }
        }
    }

    .green {
        color: #50D1B2;
    }

    .red {
        color: #EA5455;
    }

    .compareBox {
        font-size: 11px;
        line-height: 16px;
        text-align: right;
    }
}

.taskBox {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background: $background;
}

%boxStyles {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}