@import '../../styles/theme.scss';

.form {
  margin: 20px;
}

.forgotPassword {
  text-decoration: none;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 32px;
}

.shadowCard {
  top: 50%;
  position: fixed;
  width: 926px;
  overflow: visible !important;
  height: 624px;
  padding: 30px 10px;
  background-image: url(../../assets/house-background.png);
  background-size: contain;
  border-radius: 32px !important;
  background-position: left;
  background-repeat: no-repeat;
  margin: 0 auto;
  box-shadow: 8px 16px 32px 0 rgb(0 0 0 / 19%) !important;
  transform: translateY(-50%);
  left: 0;
  right: 100px;
}

.shadowCard:after {
  position: absolute;
  background-color: rgba(36, 166, 211, 1);
  opacity: 0.8;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: '';
  border-radius: 32px;
}

.form-control {
  width: 100%;
}

.formBox {
  background-color: #fff;
  padding: 0px;
  height: 690px;
  position: absolute;
  z-index: 99;
  width: 616px;
  box-shadow: 8px 16px 32px 0 rgb(0 0 0 / 19%);
  top: 50%;
  left: 0;
  border-radius: 32px;
  transform: translateY(-50%);
  overflow: auto;
}

.containerCard {
  min-height: 100vh;
}

.wrapper {
  background-color: #E4F5FC;
  background-image: url(../../assets/main-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.leftBox {
  position: relative;
  z-index: 9;
  text-align: center;
  padding: 40px 30px 40px 30px;
  height: 100%;

}

.formboxWrapper {
  position: relative;
  height: 100%;
}



.innerContainer {
  height: 100%;
  position: relative;
}

.subTitle2 {
  font-weight: 600 !important;
  font-size: 16px !important;
}

.leftBox img {
  max-width: 180px;
  margin: 0 auto 100px;
  display: block;
}

.whiteBorderBtn {
  color: #fff !important;
  border-color: #fff !important;
}

.Title1 {
  font-size: 24px !important;
  font-weight: 600 !important;
}


.singinBox {
  position: relative;
  height: 100%;
}

.signinWrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.forgotPassword {
  font-size: 16px;
  color: $primaryMain;
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .formBox {
    background-color: #fff;
    padding: 0px;
    height: auto;
    position: unset;
    z-index: 99;
    width: auto;
    box-shadow: 8px 16px 32px 0 rgba(0, 0, 0, 0.19);
    top: auto;
    right: auto;
    border-radius: 32px;
    transform: none;
    overflow: unset;
  }
}