@import '../../../styles/theme.scss';

.appLayout {
  height: 100%;
  width: 100%;
  display: flex;
  background: $backgroundShade;
}

.appContainer {
  width: 100%;
  height: 100%;
  padding: 37px 20px 20px;
  .content {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}
