@import '../../../../styles/theme.scss';

.contacts {
  font-family: $PrimaryfontFamily  !important;

  .breadcrumbsBox {
    ol {
      li {
        a {
          @extend %breadcrumbsBoxStyle;

          &:hover {
            text-decoration: none;
          }
        }

        p {
          @extend %breadcrumbsBoxStyle;
        }
      }
    }
  }
}

.tabingBox {
  margin-top: 28px;

  div[role='tablist']~ {
    span {
      display: none;
    }
  }

  .textareaAutosize {
    padding: 10px;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    border-color: #dbdade;
    border-radius: 6px;
  }

  button.tabButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 10px;
    padding: 11px 20px;
    min-height: unset;
    gap: 5px;
    text-transform: unset;

    img {
      max-width: 20px;
      margin-bottom: 0;
    }

    &[aria-selected='true'] {
      color: $background;
      background: linear-gradient(72.47deg, #26abe2 22.16%, #3dbbee 76.47%);
      box-shadow: 0px 2px 4px rgba(165, 163, 174, 0.3);
      border-radius: 6px;

      img {
        margin-right: 5px;
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
      }
    }
  }

  //   .MuiBox-root {
  //     span.MuiTabs-indicator {
  //       display: none;
  //     }
  //   }
  .tabingBoxPanel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: $background;
    box-shadow: 0px 4px 18px rgba(75, 70, 92, 0.1);
    border-radius: 0 0 6px 6px;
    padding: 0px 0px 100px 0px;
    min-height: calc(100vh - 250px);
    // overflow-y: auto;
    margin-top: 28px;

    .tabingBoxTitle {
      padding: 40px 26px 20px;
      display: flex;
      justify-content: space-between;

      .vanderNameAvatar {
        padding: 0;

        .listingAvatarLg {
          display: flex;
          flex-wrap: wrap;

          li {
            width: 22%;

            h6 {
              .nps {
                font-size: 8px;
              }
            }
          }
        }

        ul {
          padding: 0;

          li {
            padding: 0;

            .avatarIcon {
              min-width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 0;

              >div {
                width: auto;
                display: flex;
                align-items: baseline;
              }

              img {
                max-width: 100%;
                width: auto;
                height: auto;
              }
            }

            .avatarContent {
              h4 {
                margin: 0 0 10px;
                font-weight: 600;
                font-size: 18px;
                line-height: 15px;
                color: $grayTextColor;
              }

              h6 {
                margin: 0;
                font-weight: 400;
                font-size: 10px;
                display: flex;
                align-items: center;
                gap: 10px;
              }
            }
          }
        }

        .contectEmail {
          display: flex;

          li {
            width: auto;
            display: flex;
            align-items: center;

            .avatarIcon {
              >div {
                align-items: center;
              }
            }
          }
        }

        .listingAvatarSmall {
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;

          li {

            // width: 22%;
            .avatarContent {
              h4 {
                font-size: 12px;
                line-height: 14px;
                margin-bottom: 5px;
              }

              h6 {
                font-weight: 400;
                font-size: 8px;
              }
            }
          }
        }
      }
    }
  }

  div[role='tabpanel'] {
    width: 100%;
    height: 100%;

    >.MuiBox-root {
      width: 100%;
      padding: 0;
    }
  }
}

button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.leadBtn {
    background: $grayBgColor;
    padding: 5px 15px;
    font-size: 18px;
    line-height: 24px;
    margin-left: auto;
    display: flex;

    &:hover {
      background: $grayBgColor;
    }
  }
}

.successBox {
  background: $successColor  !important;
  color: $background;
  border-radius: 3px;
  border-radius: 3px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 5px;
  gap: 3px;
}

.errorBox {
  background: $errorColor  !important;
  color: $background;
  border-radius: 3px;
  border-radius: 3px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 5px;
  gap: 3px;
}

%breadcrumbsBoxStyle {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}

.tabingBoxBody {
  padding: 20px 24px;
}

.tabingBoxTabTwo {
  margin-bottom: 25px;

  div[aria-label='basic-tabs-example'] {
    gap: 0px;
  }

  div[role='tablist']~ {
    span {
      display: block;
      background: $grayBgColor;
    }
  }

  .tabs {
    gap: 16px;
  }

  .imgIcon {
    margin-bottom: 0 !important;
  }

  // div[role='tabpanel'] {
  // margin-top: 20px;
  // }
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-weight: 600;
    font-size: 18px;
    padding: 5px 10px;
    color: $grayTextColor5D;
    text-transform: unset;

    img {
      margin-bottom: 0;
    }

    &[aria-selected='true'] {
      color: $grayTextColor5D;
    }
  }
}


.switchBtnGroup {
  margin: 50px 0 0;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  flex-direction: row;
  gap: 10px;

  label {
    font-size: 17px;
    line-height: 19.98px;
  }

  .switchBtn {
    display: flex;
    margin: 0;
  }
}