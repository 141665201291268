@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;500;600;700;800;900&display=swap');

$background: #ffffff;
$backgroundShade: #f9f9f9;
$primaryMain: #27aae1;
$primaryHighlight: #39c5ff;
$primaryTextColor: #060606;
$secondaryTextColor: #666;
$borderColor: #0000005b;
$backgroundBlue : #3DBBEE;
$grayTextColor : #4B465C;
$grayTextColor5D : #5D596C;
$grayBgColor : #B4B4B4;
$PrimaryfontFamily:'Public Sans', sans-serif;
$secondaryFontFamily :'Poppins', sans-serif;
$secondaryGrayColor: #7E7E8F;
$successColor: #50D1B2;
$errorColor:rgba(234, 84, 85, 1);




