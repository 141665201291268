@import 'styles/theme.scss';

.historyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  gap: 10px;

  .messageBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 15px;
    width: 100%;

    .icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 10px;

      width: 38px;
      height: 38px;

      border-radius: 50px;

      img {
        width: 22px;
        height: 22px;
      }
    }
  }

  .contentBox {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 6px;
    // width: 665px;
    width: 100%;
  }

  .titleBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 13px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #656575;

    .date {
      color: #9A9AAF;
    }
  }

  .noteBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 13px;
    width: 100%;

    .note {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 18px;
      gap: 10px;

      .noteText {
        white-space: pre-line;
      }
    }
  }

  .button {}
}

.leftHistoryContainer {
  align-items: flex-start;

  .messageBox {
    .icon {
      background: #FFE792;
    }
  }

  .contentBox {
    align-items: flex-start;
  }

  .noteBox {
    .note {
      background: #5CAFFD;
      border-radius: 0px 16px 16px 16px;
    }

    .noteText {
      color: #FFFFFF;
    }
  }
}

.rightHistoryContainer {
  align-items: flex-end;

  .messageBox {
    justify-content: flex-end;

    .icon {
      background: #E8EDF2;
      order: 2;
    }
  }

  .contentBox {
    align-items: flex-end;

    .noteBox {
      justify-content: flex-end;

      .note {
        background: #E8EDF2;
        border-radius: 16px 0px 16px 16px;
        order: 2;
      }

      .noteText {
        color: #07070C;
      }
    }
  }

  .textarea {
    order: 3
  }

  .saveButton {
    order: 2
  }

  .cancelbutton {
    order: 1
  }
}


@media screen and (max-width: 992px) {
  .historyContainer {
    .contentBox {
      width: 100%
    }
  }
}