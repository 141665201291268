@import '../../../styles/theme.scss';

.headerWrap {
  margin-bottom: 20px;
}
.accountImage {
  border-radius: 30px;
  background-color: #000;
  max-width: 25px;
  height: 25px;
}
