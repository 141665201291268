@import '../../../styles/theme.scss';

.appMenu {
  height: 100%;
  background-color: $background;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  min-width: 80px;
  max-width: 80px;

  .addContact {
    font-size: 0px;
    min-width: 40px;

    span {
      margin-right: 0;
    }
  }
}

.appMenuHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: inherit;

  .appLogo {
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
    transition: inherit;
  }
}

.menuOpen {
  min-width: 240px;
  max-width: 240px;
  transition: all 0.3s ease-in-out;
  position: relative;

  .addContact {
    font-size: 12px;
  }

  .appLogo {
    transition: inherit;
    visibility: visible;
    opacity: 1;
    height: 46px;
    width: 153px;
  }
}

.menuList {
  display: flex;
  gap: 10px;
  flex-direction: column;
  transition: inherit;
  margin-top: 60px;
  margin-bottom: 50px;
}

.menuListSetting {
  display: flex;
  gap: 10px;
  flex-direction: column;
  transition: inherit;
  margin-bottom: 50px;
}

.topMenu {
  min-height: 65%;

  .menuLabel {
    font-size: 11px;
    color: #4b465c;
    font-weight: 300;
  }
}

.menuItem {
  gap: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  transition: inherit;
  cursor: pointer;
  padding: 6px 10px;
  color: #4b465c;

  &:hover {
    background-color: $backgroundShade;
  }

  .icon {
    display: inline-flex;
    color: #4b465c;
  }
}

.selected {
  background-color: $backgroundShade;
}

/* Create Contact Dialog */

.drawer {
  background-color: unset !important;
  box-shadow: unset !important;
}

.dialogInner {
  width: 514px;
  height: 100%;
  background: linear-gradient(184deg, rgba(61, 188, 238, 0.315) 0%, rgba(255, 255, 255) 30.92%);
  padding: 10px 30px 20px 30px;
  overflow: auto;
  position: relative;
}



/* Create Contact Dialog End */
